/* From Uiverse.io by Creatlydev */
.button {
  cursor: pointer;
  border: none;
  /* background: #7808d0; */
  background: #ff0000;
  color: #fff;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  position: fixed;
  display: grid;
  place-content: center;
  transition: background 300ms, transform 200ms;
  font-weight: 600;
  bottom: 5px;
  right: 3px;
  z-index: 111111111111111111111;
}

.button__text {
  position: absolute;
  inset: 0;
  animation: text-rotation 8s linear infinite;

  > span {
    position: absolute;
    transform: rotate(calc(21deg * var(--index)));
    inset: -7px;
    font-size: 12px;
  }
}

.button__circle {
  position: relative;
  width: 35px;
  height: 35px;
  overflow: hidden;
  background: #fff;
  color: #7808d0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__icon--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button:hover {
  background: #000;
  transform: scale(1.05);
}

.button:hover .button__icon {
  color: #000;
}

.button:hover .button__icon:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button:hover .button__icon--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

@keyframes text-rotation {
  to {
    rotate: 360deg;
  }
}

@media (max-width: 850px) {
  .button {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 850px) {
  .button__circle {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 850px) {
  .button__text {
    > span {
      font-size: 11px;
      position: absolute;
      transform: rotate(calc(20deg * var(--index)));
      inset: -8px;
    }
  }
}
