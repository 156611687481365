.stickers {
  display: block;
}
.tiles1 {
  display: block;
}
.tiles2 {
  display: none;
}
.verticalHeading {
  font-size: 1.8vw;
  text-orientation: upright;
  writing-mode: vertical-lr;
  color: black;
  font-family: BebasNeue;
  margin: 10px;
  align-self: center;
}
.horizontalHeading {
  color: black;
  font-family: BebasNeue;
  font-size: 3vw;
  text-align: center;
}
.paraStyleVertical {
  font-size: 1vw;
  text-align: center;
  color: black;
  font-family: FuturaLondon;
  font-weight: 600;
  margin: 10px;
}

.paraStyle {
  font-size: 1.4vw;
  text-align: center;
  color: black;
  font-family: FuturaLondon;
  font-weight: 600;
  margin: 10px;
}

@media (orientation: portrait) {
  .whatsnext {
    top: 40%;
  }
}
@media (max-width: 500px) {
  .stickers {
    display: none;
  }
}
@media (orientation: portrait) {
  .tiles1 {
    display: none;
  }
}
@media (orientation: portrait) {
  .tiles2 {
    display: block;
  }
}

@media (orientation: portrait) {
  .verticalHeading {
    font-size: 6vw;
  }
}
@media (orientation: portrait) {
  .horizontalHeading {
    font-size: 8vw;
  }
}
@media (orientation: portrait) {
  .paraStyle {
    font-size: 3.3vw;
  }
}
@media (orientation: portrait) {
  .paraStyleVertical {
    font-size: 3.3vw;
  }
}

.collaor {
  color: #a091cf #9391cf #cf9191 #2f2c52 #522c3a #522c45 #522c50 #4e2c52 #452c52
    #3c2c52 #2c2d52 #2c3852 #2c4752 #2c3e52;
}

.home-ending {
  font-family: Roboto-Regular;
}

.roboto {
  font-family: Roboto-Regular;
}
