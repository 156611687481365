.cube {
  height: 8vmax;
  width: 600px;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate-cube 6s infinite;
  animation-delay: 5000ms;
  /* perspective: 1000px; */
}

.cube-face {
  position: absolute;
  width: 100%;
  height: 8vmax;
  line-height: 8vmax;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: rgb(255, 255, 255);
  font-size: 4vmax;
  font-weight: 300;
  text-align: center;
  /* background-color: lightcoral; */
}

.face1 {
  transform: translateZ(4vmax);
  /* background-color: lightskyblue; */
}
.face2 {
  transform: rotateX(90deg) translateZ(4vmax);
  /* background-color: lightgreen; */
}

.face3 {
  transform: rotateX(180deg) translateZ(4vmax);
  /* background-color: lightseagreen; */
}
.face4 {
  transform: rotateX(270deg) translateZ(4vmax);
  /* background-color: lightsteelblue; */
}

@keyframes rotate-cube {
  0% {
  }
  25% {
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-180deg);
  }
  75% {
    transform: rotateX(-270deg);
  }
  100% {
    transform: rotateX(-360deg);
  }
}
