body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "BebasNeue";
  src: local("BebasNeue"),
    url("./Assets/Fonts/BebasNeue-Regular.ttf") format("truetype");
  font-display: swap;

  /* font-weight: bold; */
}

@font-face {
  font-family: "FuturaLondon";
  src: local("FuturaLondon"),
    url("./Assets/Fonts/FuturaLondon.otf") format("opentype");
  font-display: swap;
  /* font-weight: bold; */
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./Assets/Fonts/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
  /* font-weight: bold; */
}
@font-face {
  font-family: "Concord";
  src: local("ConcordThin"),
    url("./Assets/Fonts/ConcordThin.ttf") format("truetype");
  font-display: swap;
  /* font-weight: bold; */
}
@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./Assets/Fonts/Rubik.ttf") format("truetype");
  font-display: swap;
  /* font-weight: bold; */
}
