.social-buttons-sticky-box {
  width: 45px;
  height: 146px;
  right: 1vh;
  bottom: 33vh;
  border-radius: 13vh;
  z-index: 100000;
  position: fixed;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(2px);
  background-color: rgb(224 116 213 / 36%);
  background-image: linear-gradient(338deg, #3e373740, #e43b3b4f);
  box-shadow: rgb(0 0 0 / 50%) 10px 10px 10px;
}
