.blocks-parent {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.plus-p {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
  padding-bottom: 50px;
}

.title-text {
  color: rgb(0, 0, 0);
  font-size: 7vw;
  text-align: center;
  font-family: FuturaLondon;
  height: 14rem;
  align-content: center;
}
@media (max-width: 850px) {
  .plus-p {
    padding-top: 0px;
  }
}
@media (max-width: 850px) {
  .blocks-parent {
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-evenly;
    height: 1500px;
  }
}
@media (max-height: 770px) {
  .title-text {
    height: 45rem;
  }
}
