.futureHeading {
  color: white;
  font-family: FuturaLondon;
  font-size: 12vw;
}

.futurePara {
  color: white;
  font-family: FuturaLondon;
  font-size: 2vw;
  /* font-weight: 600; */
  letter-spacing: 2px;
  inline-size: 70%;
  position: absolute;
  right: 5%;
  bottom: 0vh;
  opacity: 0;
  animation: fadeInLandscape 2s linear;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  /* animation-delay: 1s; */
}

@media (orientation: portrait) {
  .futurePara {
    font-size: 4vw;
    animation: fadeInPortrait 2s linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes fadeInPortrait {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(-30vh);
  }
}
@keyframes fadeInLandscape {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(-40vh);
  }
}
