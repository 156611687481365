.white-header-font {
  /* margin-left: 30px; */
  font-family: FuturaLondon;
  font-size: 40px;
  color: white;
  font-weight: 600;
  width: 100%;
}
.black-header-font {
  /* margin-left: 30px; */
  font-family: FuturaLondon;
  font-size: 40px;
  color: black;
  font-weight: 600;
  width: 100%;
}
.drawer-font {
  font-family: FuturaLondon;
  font-size: 30px;
  color: black;
  font-weight: 600;
  width: 100%;
}

.header-base {
  display: flex;
}

.drawer-base {
  display: none;
}

@media (max-width: 850px) {
  .header-base {
    display: none;
  }
}

@media (max-width: 850px) {
  .drawer-base {
    display: flex;
    flex-direction: column;
  }
}
