.section-container {
  object-fit: contain;
  justify-content: space-between;
  max-width: 1300px;
  background-color: black;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.section-image {
  max-height: 800px;
  max-width: 50%;
  object-fit: contain;
}

.section-heading {
  width: 40%;
  font-family: BebasNeue;
  font-size: 50px;
  color: #ffc200;
}

.section-para {
  font-size: 25px;
  color: #ffc200;
  font-weight: 300;
}

@media (max-width: 991px) {
  .section-image {
    height: 500px;
    max-width: 80%;
  }
}
@media (max-width: 991px) {
  .section-container {
    flex-direction: column;
    margin-bottom: 50px;
  }
}
@media (max-width: 991px) {
  .section-heading {
    margin-top: 50px;
    max-width: 80%;
    width: auto;
  }
}
