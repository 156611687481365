.ticker-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
.ticker-wrapper {
  width: 100%;
  padding-left: 100%;
  background-color: transparent;
}
@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.ticker-transition {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 20s;
}
.ticker-transition:hover {
  animation-play-state: paused;
  cursor: pointer;
}
.ticker-item {
  display: inline-block;
  padding: 0 2rem;
  color: white;
  font-family: BebasNeue;
}
.ticker-fontSize {
  font-size: 4.5vw;
}
@media (orientation: portrait) {
  .ticker-fontSize {
    font-size: 9vw;
  }
}
