.cces-start {
  color: rgb(0, 0, 0);
  font-size: 5vw;
  text-align: center;
  font-weight: 600;
  font-family: FuturaLondon;
  margin: 10px;
}

.cces-question-text {
  color: rgb(0, 0, 0);
  font-size: 6vw;
  text-align: center;
  font-family: Concord;
  padding-top: 30px;
}
@media (max-height: 770px) {
  .cces-start {
    margin: 30px;
  }
  .cces-question-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
