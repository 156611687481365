.background-gradient {
  background:
    /* "radial-gradient(rgb(159,165,214) 20px, rgb(231,245,201) 150%)", */
     /* "radial-gradient(rgb(231,245,201) 20px, rgb(159,165,214)  150%)", */ 
     /* radial-gradient(
    #6d678f 20px,
    #f6b5cd 150%
  ); */ 
  /* radial-gradient(
    #2e3192 20px,
    #1bffff 150%
  ); */
   /* radial-gradient(
    #ee9ca7 20px,
    #ffdde1 150%
  ); */
  /* radial-gradient(
    #614385 20px,
    #516395 150%
  ); */
  /* radial-gradient(
    #ff5f6d 20px,
    #ffc371 150%
  ); */
  /* radial-gradient(
    #c33764 20px,
    #1d2671 150%
  ); */
  /* radial-gradient(
    #93a5cf 20px,
    #e4efe9 150%
  ); */
  /* radial-gradient(
    #09203f 20px,
    #537895 150%
  ); */
  /* radial-gradient(
    #fdfcfb 20px,
    #e2d1c3 150%
  ); */
  /* radial-gradient(
    #d9afd9,
    #97d9e1
  ); */
  /* linear-gradient(
    -45deg,
    #ee7752,
    #e73c7e,
    #516395,
    #d9afd9
  ); */ 
  /* radial-gradient(
    #ee9ca7 20px,
    #ffdde1 150%
  ); */ 
  /* radial-gradient(
    #f6c6c3 20px,
    #f7a49d 150%
  ); */
   /* radial-gradient(
    #e5f3e5 20px,
    #5aa9e9 150%
  ); */ 
  /* radial-gradient(
    #7db6be,
    #d3f3ef
  ); */ 
  /* radial-gradient(
    #d8b8f3,
    #b9b8f3
  ); */
  /* radial-gradient(
    #c98283,
    #f2d8dd
  ); */
  /* radial-gradient(
    #e4aec5,
    #f2d8dd
  ); */ radial-gradient(
    #e4aec5,
    #feedf3
  );
  /* radial-gradient(
    #e89ba8,
    #d4b9d1
  ); */
  /* radial-gradient(
    #e4aec5,
    #d4b9d1
  ); */
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  padding-left: 20;
  padding-right: 20;
  padding-top: 30;
  padding-bottom: 80;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.blur::before {
  backdrop-filter: blur(1px);
  background-color: rgba(243, 22, 2, 0.4);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}
