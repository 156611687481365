.orangeBase {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  /* background-color: coral; */
  background-color: #f4f4f4;
}
.baseShadowCU {
  width: 70vw;
  height: 70vh;
  align-self: center;
  margin-top: 100px;
  background-color: #f4f4;
  box-shadow: 0px 0px 11px 11px rgba(0, 0, 0, 0.19);
  display: flex;
}
