/* From Uiverse.io by Yaya12085 */
.download-app-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 1);
  padding: 0.625rem 1.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  outline: 0;
  transition: all 0.2s ease;
  text-decoration: none;
  /* width: 55%;
  height: 18%; */
  width: 9rem;
  height: 2.8rem;
  margin: 2rem;
}

@media (max-width: 850px) {
  .download-app-button {
    margin: 0.35rem;
  }
}

.download-app-button:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.texts {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.text-1 {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-2 {
  font-weight: 600;
}
