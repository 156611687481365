.note-text {
  color: black;
  font-size: 6vw;
  width: 100%;
  margin-left: 7vw;
  font-family: FuturaLondon;
  padding-top: 50px;
  padding-bottom: 50px;
}
.give-it-a-go-text {
  color: rgb(0, 0, 0);
  font-size: 4.8vw;
  margin-top: 30px;
  width: 100%;
  font-family: Rubik;
}
.pointers-title {
  color: white;
  font-size: 6vw;
  width: 100%;
  font-family: FuturaLondon;
  font-weight: 600;
  padding-top: 100px;
  padding-bottom: 25px;
}
.pointers-heading-text {
  font-size: 4.8vw;
  width: 100%;
  margin-top: 30px;
  font-family: Concord;
  font-weight: 600;
  color: black;
}
.pointers-sub-text {
  color: white;
  font-size: 3.2vw;
  width: 100%;
  margin-left: 7vw;
  margin-top: 10px;
  font-family: Rubik;
}

@media (max-height: 770px) {
  .note-text {
    font-size: 2.8vw;
  }
  .give-it-a-go-text {
    font-size: 3vw;
  }
  .pointers-heading-text {
    font-size: 3.8vw;
  }
  .pointers-sub-text {
    font-size: 2.2vw;
  }
  .pointers-title {
    margin-top: 30px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
